import "./App.scss";

function App() {
  return (
    <div className="App">
      <header>
        <h2>Hello I&apos;m,</h2>
        <h1>Sudip Pandey</h1>
        <center>
          <small style={{ color: "white" }}>
            Powered By{" "}
            <a
              href="https://dahal-nischal.com.np"
              style={{ color: "white", fontWeight: 500 }}
            >
              {" "}
              neeswebservices
            </a>{" "}
          </small>
        </center>
      </header>
    </div>
  );
}

export default App;
